"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tiny_slider_1 = require("tiny-slider");
document.addEventListener("DOMContentLoaded", function () {
    var mql = window.matchMedia('(min-width:768px)');
    /*
     * Properties
     */
    var handleWindowResize = function () {
        var vwi = document.documentElement.clientWidth / 100;
        var vhi = document.documentElement.clientHeight / 100;
        document.documentElement.style.setProperty('--vwi', "".concat(vwi, "px"));
        document.documentElement.style.setProperty('--vhi', "".concat(vhi, "px"));
        document.querySelectorAll('#epilogue p b').forEach(function (element, index) {
            element.closest('span').style.setProperty('--width', "".concat(element.offsetWidth));
        });
    };
    window.addEventListener('resize', handleWindowResize, { passive: true });
    handleWindowResize();
    /*
     * Slider
     */
    var slider = (0, tiny_slider_1.tns)({
        container: '.slider',
        mode: 'gallery',
        controls: false,
        nav: false,
        autoplayButtonOutput: false,
        speed: 1000,
        autoplay: true
    });
});
